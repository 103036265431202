import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default class NotFoundPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    console.log("hanlechange--",event.target.value)
    this.setState({value: event.target.value});
  }

  render() {
    return (
        <Layout>
          <main >

            <section id="error_container">
              <div id="error_detail">
                <div class="content">
                  <h5>Page Not Found</h5>
                  <p>The page you were looking for does not exist.</p>
                  <h2></h2>
                  <a href="/" class="button">Return to Homepage</a>
                </div>
                <div class="error-image"></div>
              </div>
            </section>
            <article class="locator-callout">

              <div class="quick-form-container">


                <div class="product-locator-search">
                  <h2>Find Our Products</h2>
                  <form data-url="https://test.wrightbrand.com/locator/" action="https://test.wrightbrand.com/locator/" method="get" novalidate="novalidate">
                    <input value="ANY_ANY" id="PROD" name="PROD" type="hidden" />
                    <div class="input-wrapper">
                      <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxlength="5" name="ZIP" placeholder="Enter Zip" type="tel" value={this.state.value} onChange={this.handleChange} />
                      <input type="submit" value="Locate Bacon" />
                      <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                    </div>
                  </form>
                </div>
              </div>
            </article>
          </main>
        </Layout>
    )
  }
}

